.loading-dot {
  opacity: 1;
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.dot-0 {
  animation-delay: -0.1s;
}
.dot-0 {
  animation-delay: -0.2s;
}
.dot-0 {
  animation-delay: -0.3s;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
