.Login {
  background: url('../../images/splash-2.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    height: 100vh;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
    background: rgba(255, 255, 255, 0.75);
    padding: 20px;
    border-radius: 5%;
  }

  .Login h3 {
    text-align: center;
  }

  .Login img {
    margin: 0 auto;
  }
}

.button {
  background: rgb(0, 135, 62);
  color: rgb(255, 223, 0);
}

.tenant-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgb(0, 135, 62);
  font-size: 35px;
  color: rgb(255, 223, 0);
  text-align: center;
  line-height: 100px;
  margin: 0 auto;
}

.tenant-letters {
  width: 75px;
  height: 75px;
  background-color: #248245;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  color: white;
  /* font: normal small-caps normal 16pt/1.4 Helvetica; */
  vertical-align: bottom !important;
}

.credits {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ffffffaa;
  padding: 0.5em 1em;
  border-top-left-radius: 5px;
}
.credits a {
  text-decoration: underline;
  color: #333;
}
.shadow {
   -moz-box-shadow:    0 0 10px #555;
   -webkit-box-shadow:  0 0 10px #555;
   box-shadow:          0 0 10px #555;
}

/* .bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */
