:root {
  --topbar-nav-bg: #343a40;
  --topbar-nav-bg-accent: #484a4d;
  --topbar-nav-text-color: #dadce1;
  --topbar-nav-size: 59px; /* 60px*/
  --topbar-nav-border: 1px solid #474a4d;
  --topbar-nav-border-radius: 8px;
  --topbar-nav-speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.topbar-navbar a, .topbar-menu-item a {
  color: var(--topbar-nav-text-color);
  text-decoration: none;
}

.topbar-menu-text {
  color: var(--topbar-nav-text-color);
  text-decoration: none;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  letter-spacing: 0.3ch;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  text-align: center;
}
.navbar-brand svg {
  transform: rotate(0deg) scale(1);
  transition: all 150ms;
  fill: #ffd700;
}

.navbar-brand:active svg{
  transform: rotate(-360deg) scale(0.75, 0.75);
}
.navbar-brand:active {
  font-weight: bold;
}

/* <nav> */
.topbar-navbar {
  /* height: var(--topbar-nav-size); */
  /* background-color: var(--topbar-nav-bg); */
  padding: 0 1rem;
  border-bottom: var(--topbar-nav-border);
  z-index: 1021;
}

/* <ul> */
.topbar-navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between; /* flex-end; */
  z-index: 1022;
}

.topbar-nav-item {
  width: calc(var(--topbar-nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar-icon-button {
  --button-size: calc(var(--topbar-nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 10%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  /* transition: filter 300ms; */
  transition: all 200ms ease;
}

.topbar-icon-button:hover {
  filter: brightness(1.2);
}

.topbar-icon-button:active {
  transform: scale(0.95);
  border-radius: 30%;
}

.topbar-icon-button svg {
  fill: var(--topbar-nav-text-color);
  width: 20px;
  height: 20px;
}

.topbar-dropdown {
  position: absolute;
  top: 49px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--topbar-nav-bg);
  border: var(--topbar-nav-border);
  border-radius: var(--topbar-nav-border-radius);
  padding: 1rem;
  overflow: hidden;

  transition: height var(--topbar-nav-speed) ease;
  z-index: 2999;

  box-sizing: content-box !important;
}

.topbar-menu {
  width: 100%;
  z-index: 2999;
}

.topbar-menu-item {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--topbar-nav-border-radius);
  transition: background var(--topbar-nav-speed);
  padding: 0.5rem;
}

.topbar-menu-text {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--topbar-nav-border-radius);
  transition: background var(--topbar-nav-speed);
  padding: 0.5rem;
}

.topbar-menu-item .topbar-icon-button:hover {
  filter: none;
}

.topbar-icon-button-right {
  align-self: flex-end;
}

.topbar-menu-item:hover {
  background-color: rgba(255, 255, 255, .25);
}

.topbar-icon-right {
  margin-left: auto;
}

/* Primary menu */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--topbar-nav-speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
}

/* Secondary menu */
.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--topbar-nav-speed) ease;
}

.menu-secondary-exit {
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--topbar-nav-speed) ease;
}